define(['app'], function(app) {

  

  var productViewMoreInformation = function() {
    var component = {};

    var _config = {
      productAttribute: '[data-child-id]',
      productSku: 'data-child-id'
    };

    var _init = function(element) {
      component.element = element;
      component.productElement = app.element.closest(component.config.productAttribute, element).element;
      component.productId = component.productElement.getAttribute(component.config.productSku);

      app.subscribe('productViewMoreInformation/newUrl', component.variationReceived);

      component.bind();
      return component;
    };

    var _bind = function() {
      component.element.addEventListener('click', component.tracking);
    };

    var _tracking = function() {
      app.publish('tracking/record', 'Product | quickBuyMoreInformation', 'clicked', component.productId);
    };

    var _variationReceived = function(data) {
      component.element.setAttribute('href', data);
    };

    component.init = _init;
    component.config = _config;
    component.bind = _bind;
    component.tracking = _tracking;
    component.variationReceived = _variationReceived;

    return component;
  };

  return productViewMoreInformation;
});
